@media print {
    body {
        visibility: hidden;
    }
    .my-print {
        visibility: visible;
        overflow: scroll !important;
        position: fixed !important;
        top: 0 !important;
        left: 0 !important;
    }
}