@tailwind base;
@tailwind components;
@tailwind utilities;

* 
  /* Track */
  ::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  margin: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* styling */

:root {
  /* Tabble.tsx */
  --table-button-color: #ffffff;
  --table-button-color-darkmode: #2b2c37;
  --table-button-text-color: #000000;
  --table-button-text-color-darkmode: #ffffff;

  /* ordersList.tsx */
  --order-bar-view-details-color: #ffffff;
  --order-bar-view-details-color-darkmode: #2b2c37;
  --order-bar-view-details-text-color: #000000;
  --order-bar-view-details-text-color-darkmode: #ffffff;

  /* Checkout.tsx */
  --primary-button-color: #ffffff;
  --primary-button-color-darkmode: #2b2c37;
  --primary-button-text-color: #000000;
  --primary-button-text-color-darkmode: #ffffff;
  --primary-button-hover-color: #e0e0e0;
  --primary-button-hover-color-darkmode: #3a3b47;
  --primary-button-hover-text-color: #000000;
  --primary-button-hover-text-color-darkmode: #ffffff;
  --popup-color: #ffffff;
  --popup-color-darkmode: #2b2c37;
  --popup-text-color: #000000;
  --popup-text-color-darkmode: #ffffff;
  --popup-secondary-button-color: #e0e0e0;
  --popup-secondary-button-color-darkmode: #3a3b47;
  --popup-secondary-button-text-color: #000000;
  --popup-secondary-button-text-color-darkmode: #ffffff;

  
}

